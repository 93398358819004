<template>
  <el-card style="overflow: auto;">
    <el-form :model="searchForm" label-width="100px" class="demo-form-inline">
      <el-row>
        <el-col :xs="24" :sm="12" :md="8">
          <el-form-item label="商品类别">
            <el-cascader
              :options="goodsTypeList"
              v-model="searchForm.goodsTypeId"
              :props="{
                emitPath: false,
                value: 'id',
                label: 'name',
                children: 'child',
              }"
              ref="cascader"
              :filterable="true"
              clearable
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col v-if="userInfo.type != 3" :xs="24" :sm="12" :md="8">
          <el-form-item label="所属店铺">
            <el-cascader 
              :filterable="true"
              v-model="searchForm.shopId" 
              placeholder="请选择所属店铺" 
              :props = "{
                value: 'id',
                label: 'name',
                children: 'childList'
              }" 
              :options="shopList">

            </el-cascader>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8">
          <el-form-item label="商品名称">
            <el-input v-model="searchForm.name" placeholder="请输入商品名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8">
          <el-form-item label="对谁可见">
            <el-select v-model="searchForm.scopes" placeholder="请选择对谁可见">
              <el-option label="企业采购商" :value="1"></el-option>
              <el-option label="企业个人" :value="2"></el-option>
              <el-option label="顾客" :value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8">
          <el-form-item label="状态">
            <el-select v-model="searchForm.statusList" multiple placeholder="请选择状态">
              <el-option label="新增" :value="0"></el-option>
              <el-option label="待审核" :value="1"></el-option>
              <el-option label="审核未通过" :value="2"></el-option>
              <el-option label="上架" :value="3"></el-option>
              <el-option label="下架" :value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8">
          <el-form-item label="商品价格">
            <el-input-number controls-position="right" v-model="searchForm.minSaleAmount" :min="0" style="width: 49%;" placeholder="最低售价"></el-input-number>
            <span style="display: inline-block; width: 2%; text-align: center;">-</span>
            <el-input-number controls-position="right" v-model="searchForm.maxSaleAmount" :min="0" style="width: 49%;" placeholder="最高售价"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" :md="8">
          <el-form-item label="剩余库存">
            <el-input-number controls-position="right" v-model="searchForm.minInventoryCount" :min="0" style="width: 49%;" placeholder="最小库存"></el-input-number>
            <span style="display: inline-block; width: 2%; text-align: center;">-</span>
            <el-input-number controls-position="right" v-model="searchForm.maxInventoryCount" :min="0" style="width: 49%;" placeholder="最大库存"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8">
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="searchForm.createTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" style="text-align: right;">
          <el-button type="primary" @click="searchEvent">搜索</el-button>
          <el-button @click="resetEvent">重置</el-button>
        </el-col>
      </el-row>
    </el-form>
    <div class="header">
      <el-button @click="handleAdd()" type="primary">新增</el-button>
      <!-- <el-popconfirm
        title="确定要下架这些数据吗？"
        @confirm="handleBatchOffline"
        style="margin-left: 10px;"
      >
        <el-button slot="reference">上架</el-button>
      </el-popconfirm> -->
      <el-popconfirm
        title="确定要下架这些数据吗？"
        @confirm="handleBatchOffline"
        style="margin-left: 10px;"
      >
        <el-button slot="reference">下架</el-button>
      </el-popconfirm>
      <el-popconfirm
        title="确定要删除这些数据吗？"
        @confirm="handleBatchDelete"
        style="margin-left: 10px;"
      >
        <el-button slot="reference" type="danger">删除</el-button>
      </el-popconfirm>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%; margin-top: 20px;"
      @selection-change="handleSelectionChange"
      row-key="id"
    >
      <el-table-column
        type="selection"
        :reserve-selection="true"
        width="55"
      ></el-table-column>
      <el-table-column
        prop="shopName"
        label="所属店铺"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="商品名称"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="goodsTypeName"
        label="商品类别"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="saleAmount"
        label="售价"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="inventoryCount"
        label="库存"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="calculateSaleCount"
        label="已售数量"
        width="100"
      ></el-table-column>
      <el-table-column prop="property" label="商品属性" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.property == 1 ? '服务类' : '实物类' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="scopes" label="对谁可见" width="220">
        <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.scopes" :key="index">
            <span v-if="item == 1">企业采购商、</span>
            <span v-if="item == 2">企业个人、</span>
            <span v-if="item == 3">顾客</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" width="100">
        <template slot-scope="{ row }">
          {{
            row.status == 0
              ? '新增'
              : row.status == 1
              ? '待审核'
              : row.status == 2
              ? '审核未通过'
              : row.status == 3
              ? '上架'
              : row.status == 4
              ? '下架'
              : row.status == 5
              ? '删除'
              : '--'
          }}
        </template>
      </el-table-column>
      <el-table-column prop="action" label="操作" width="180" fixed="right">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleDetail(row.id)">详情</el-button>
          <el-button
            type="text"
            @click="handleAdd(row.id)"
            style="margin-right: 10px;"
            v-if="[0, 2, 4].includes(row.status)"
          >
            修改
          </el-button>
          <el-button @click="handleEditInventoryCount(row)" type="text" v-if="[1, 2, 3, 4, 5].includes(row.status)">
            库存
          </el-button>
          <el-popconfirm
            title="确定要删除该数据吗？"
            @confirm="handleDelete(row.id)"
          >
            <el-button
              slot="reference"
              type="text"
              v-if="[0, 1, 2, 4, 5].includes(row.status)"
              style="color: red;"
            >
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <d-paging :pager="pager" @change="getGoodsList"></d-paging>
    <el-dialog title="修改库存" :visible.sync="dialogVisible" width="60%">
        <el-table :data="inventoryList" style="width: 100%">
          <el-table-column prop="specification" label="规格">
          </el-table-column>
          <el-table-column prop="externalSkuNo" label="商品编码">
          </el-table-column>
          <el-table-column prop="saleAmount" label="售价">
          </el-table-column>
          <el-table-column prop="originalAmount" label="原价（选填）">
          </el-table-column>
          <el-table-column prop="inventoryCount" label="库存">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.inventoryCount" :min="0"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column prop="specificationImage" label="规格图片">
            <template slot-scope="scope">
              <div class="wrap">
                <div style="width: 120px;height:120px;" v-if="scope.row.specificationImage">
                  <div class="img" style="width: 120px;height:120px;">
                    <img style="width: 100%;height: 100%;" :src="scope.row.specificationImage" alt="">
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div slot="footer">
          <el-button @click="dialogVisible = false, inventoryList = []">取 消</el-button>
          <el-button type="primary" @click="handleConfirmInventory">确 定</el-button>
        </div>
    </el-dialog>
  </el-card>
  
</template>
<script>
import DSearch from '@/components/d-search'
import DPaging from '@/components/d-paging'
import { options } from '@/components/options'
export default {
  name: 'ShopManage',
  components: {
    DSearch,
    DPaging,
  },
  data() {
    return {
      searchForm: {
        name: undefined,
        goodsTypeId: null,
        shopId: [],
        createTime: [],
        statusList: [],
        minSaleAmount: undefined,
        maxSaleAmount: undefined,
        minInventoryCount:undefined,
        maxInventoryCount: undefined,
        scopes: null
      },
      searchData: {
        searchItem: [
          {
            label: '商品类别',
            value: 'goodsTypeId',
            type: 'linkageSelect',
            placeholder: '',
            options: options,
            optionProps: {
              value: 'id',
              label: 'name',
              children: 'child',
            },
          },
          {
            label: '选择店铺',
            value: 'shopId',
            type: 'linkageSelect',
            placeholder: '',
            options: options,
            optionProps: {
              value: 'id',
              label: 'name',
              children: 'childList',
            },
          },
          {
            label: '名称',
            value: 'name',
            type: 'commonInput',
            placeholder: '请输入商品名称',
          },
          {
            label: '状态',
            value: 'statusList',
            type: 'commonSelect',
            placeholder: '请选择状态',
            options: [
              { value: 0, label: '新增' },
              { value: 1, label: '待审核' },
              { value: 2, label: '审核未通过' },
              { value: 3, label: '上架' },
              { value: 4, label: '下架' },
            ],
          },
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true,
        },
        params: {},
        // 列表
      },
      tableData: [],
      // 商品类别列表
      goodsTypeList: [],
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      selectRows: [], // 已选择的行
      shopList: [],
      /* 修改库存 */
      detail: {},
      inventoryList: [],
      dialogVisible: false,
      userInfo: {}
    }
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getShopList()
    this.getGoodTypeList()
    this.getGoodsList()
  },
  methods: {
    /**
     * 搜索按钮点击
     **/
    searchEvent() {
      this.getGoodsList()
    },
    /**
     * 重置按钮点击
     **/
    resetEvent() {
      this.searchForm = {
        name: undefined,
        goodsTypeId: null,
        shopId: [],
        createTime: [],
        statusList: [],
        minSaleAmount: undefined,
        maxSaleAmount: undefined,
        minInventoryCount: undefined,
        maxInventoryCount: undefined,
        scopes: null
      },
      this.getGoodsList()
    },
    // 店铺列表
    getShopList() {
      this.$api.shopapi.getShopList().then(({ data, code }) => {
        if (code == 200) {
          let childList = JSON.parse(JSON.stringify(data.childList))
          let allChildList = []
          data.childList.forEach((child) => {
            child.childList.forEach((shop) => {
              allChildList.push(shop)
            })
          })
          let allShop = {
            name: '全部',
            childList: allChildList,
          }
          childList.unshift(allShop)
          const child = [
            {
              id: data.id,
              name: data.name,
              childList: data.childList,
            },
          ]
          this.shopList = child
          this.searchData.searchItem.forEach((ele) => {
            if (ele.type === 'linkageSelect' && ele.value === 'shopId') {
              ele.options = child
            }
          })
        }
      })
    },
    // 商品类型列表
    getGoodTypeList() {
      this.$api.shopManageApi.getGoodsTypeList().then(({ data, code }) => {
        this.goodsTypeList = this.removeEmptyChildren(data)
        // this.searchData.searchItem.forEach((ele) => {
        //   if (ele.type === 'linkageSelect' && ele.value === 'goodsTypeId') {
        //     ele.options = data
        //   }
        // })
      })
    },
    // 处理商品类型第三级child为空的数据
    removeEmptyChildren(data) {
      data.forEach((item) => {
        if (item.child && item.child.length > 0) {
          this.removeEmptyChildren(item.child) // 递归处理子节点
        }
        // 如果当前节点的 typeLevel 是 3 并且 child 数组为空，则删除 child 属性
        if (item.typeLevel === 3 && item.child && item.child.length === 0) {
          delete item.child
        }
      })
      console.log('data', data)
      return data
    },
    // 商品列表
    getGoodsList() {
      const {
        name,
        goodsTypeId,
        shopId,
        createTime,
        statusList,
        minSaleAmount,
        maxSaleAmount,
        minInventoryCount,
        maxInventoryCount,
        scopes
      } = this.searchForm
      const postData = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        minSaleAmount,
        maxSaleAmount,
        minInventoryCount,
        maxInventoryCount,
        name,
        goodsTypeId,
        shopId: this.userInfo.type == 3 ? this.userInfo.shopId : shopId[shopId.length - 1],
        beginCreateTime: createTime[0],
        endCreateTime: createTime[1],
        scopes,
        statusList: statusList + ''
      }
      this.$api.shopManageApi.businessGoodsList(postData).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list
          this.pager.count = res.data.total
          console.log('res', this.pager)
        }
      })
    },
    // 去新增
    handleAdd(id) {
      console.log('id', id)
      if (id) {
        this.$router.push(`/shop-mall/shop-manage/shop-manage/edit/${id}`)
      } else {
        this.$router.push('/shop-mall/shop-manage/shop-manage/edit')
      }
    },
    // 去详情
    handleDetail(id) {
      this.$router.push(`/shop-mall/shop-manage/shop-manage/detail/${id}`)
    },
    // 删除
    handleDelete(id) {
      console.log('delete')
      const postData = [{ id }]
      this.$api.shopManageApi.goodsDelete(postData).then((res) => {
        if (res.code == 200) {
          this.$message.success('删除成功')
          this.getGoodsList()
        } else {
          this.$message.success('删除失败：' + res.msg)
        }
      })
    },
    // 批量删除
    handleBatchDelete() {
      if (this.selectRows.length == 0) {
        this.$message.warning('请选择商品')
        return
      }
      let selectRowIds = []
      this.selectRows.forEach((item) => {
        selectRowIds.push({ id: item.id })
      })
      this.$api.shopManageApi.goodsDelete(selectRowIds).then((res) => {
        if (res.code == 200) {
          this.$message.success('删除成功')
          this.getGoodsList()
        } else {
          this.$message.success('删除失败：' + res.msg)
        }
      })
    },
    // 批量下架
    handleBatchOffline() {
      if (this.selectRows.length == 0) {
        this.$message.warning('请选择商品')
        return
      }
      let selectRowIds = []
      this.selectRows.forEach((item) => {
        selectRowIds.push({ id: item.id })
      })
      this.$api.shopManageApi.goodsOffline(selectRowIds).then((res) => {
        if (res.code == 200) {
          this.$message.success('下架成功')
          this.getGoodsList()
        } else {
          this.$message.success('下架失败' + res.msg)
        }
      })
    },
    // 多选
    handleSelectionChange(val) {
      this.selectRows = val
    },
    // 修改库存
    async handleEditInventoryCount (row) {
      const { data, code, msg } = await this.$api.shopManageApi.businessGoodsDetail({id: row.id})
      if (code == 200) {
        this.detail = data
        this.inventoryList = this.detail.goodsSkus
        this.dialogVisible = true
        
      }
    },
    async handleConfirmInventory () {
      let postData = []
      this.inventoryList.forEach(item => {
        postData.push({
          goodsSkuId: item.id,
          inventoryCount: item.inventoryCount
        })

      })
      const { code, msg } = await this.$api.shopManageApi.updateGoodsInventory(postData)
      if (code == 200) {
        this.$message.success('更新成功')
        this.dialogVisible = false
        this.inventoryList = []
        this.getGoodsList()
      } else {
        this.$message.error('更新失败：' + msg)
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.tableWrap{
  height: 1000px;
  overflow: auto;
}
.reservation {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  .header {
    width: 100%;
    display: flex;
    padding: 0 40px;
    background-color: #fff;
    margin-bottom: 10px;
  }
}
</style>
